<template lang="pug">
  v-icon(:color="color").select-menu-close-btn.d-block mdi-close
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
  }
}
</script>

<style lang="scss" scoped>
.select-menu-close-btn {
  display: block;
  text-align: right;
  width: 24px;
  margin-left: auto;
  cursor: pointer;
  position: sticky;
  left: 0;
  top: 0;
  height: 0;
  z-index: 1;

  &:before {
    background-color: #fff;
  }
}
</style>
